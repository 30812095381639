<template>
    <div v-loading="$wait.is('loading.poi')" class="w-full" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-300 mb-3 pb-3">
            <p>POI</p>

            <el-button type="primary" @click="addNew">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <div v-for="poi in poiData" :key="poi.id" class="relative border-b mb-3 pb-4">
            <div>
                <div class="absolute top-0 right-0 text-red-300 hover:text-red-400 transition cursor-pointer" @click="deletePOI(poi.id)">
                    <fa-icon :icon="['fas', 'trash']" fixed-width />
                </div>
                <div class="absolute top-0 right-6 text-red-300 hover:text-red-400 transition cursor-pointer" @click="openMarkerInfoDialog(poi.id)">
                    <fa-icon :icon="['fas', 'map-marker-alt']" fixed-width class="" />
                </div>
                <p class="font-semibold">
                    {{ poi.client_name }} {{ poi.client_surname }}
                </p>
                <p class="font-semibold">
                    {{ poi.title }}
                </p>
                <p class="text-sm text-gray-400">
                    {{ poi.description }}
                </p>
                <div>
                    <p class="text-sm text-gray-600">
                        {{ poi.street }}
                    </p>
                    <p class="text-sm text-gray-600">
                        {{ poi.city }} {{ poi.post_code }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from './map.api';

export default {
    data() {
        return {
            poiData: [],
        };
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.poi');
            this.poiData = await Api.getAllPOIs();
            this.$emit('poiLoaded', this.poiData);
            this.$wait.end('loading.poi');
        },

        async addNew() {
            this.$emit('addNew');
        },

        deletePOI(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyPOIURL(id),
                actionType: 'destroy',
            });
        },

        openMarkerInfoDialog(id) {
            this.$emit('showMarkerInfoDialog', id);
        },
    },
};
</script>
