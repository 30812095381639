export default {
    async getAllPOIs() {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/map/poi`);
            return data;
        } catch {
            return [];
        }
    },

    async getPOIDetails(id) {
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/services/${id}`);
        return data;
    },

    async createPOI(formData) {
        await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/map/poi`, {
            title:       formData.title,
            description: formData.description,
            street:      formData.street,
            city:        formData.city,
            post_code:   formData.post_code,
            icon:        formData.icon,
            map_lat:     formData.map_lat,
            map_lng:     formData.map_lng,
            client_uuid: formData.client_uuid,
        });
    },

    async updatePOI(id, data, timbankHours, timbankMinutes) {
        await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/services/${id}`, {
            name:                   data.name,
            price_type:             data.price_type,
            price:                  parseInt(data.price.replace(',', '').replace('.', '')),
            tax:                    25,
            rut:                    data.rut,
            rot:                    data.rot,
            timbank_volume_hours:   timbankHours,
            timbank_volume_minutes: timbankMinutes,
            category_id:            data.category.id,
        });
    },

    destroyPOIURL(id) {
        return `${process.env.VUE_APP_CORE_API_URL}/services/map/poi/${id}`;
    },
};
